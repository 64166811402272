<i18n>
{
  "de": {
    "descriptionLabel": "Bezeichnung",
    "alreadyExistsError": "Name bereits vorhanden"
  }
}
</i18n>

<template>
  <EditContainer
    :bind-form-state="formState"
    class="edit-tag-group"
    :allow-edit="getPortfolioPermission('EDIT_TAGS')"
    @container="onFormEvent"
  >
    <FormRow :label="$t('descriptionLabel')">
      <TextInput
        v-model="model.name"
        :min-length="1"
        :edit="isEditing"
        :invalid-values="invalidValues"
        :invalid-values-error-message="$t('alreadyExistsError')"
        @validation="onValidation($event, 'tag-group-name')"
      />
    </FormRow>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'
import EditContainer from '@/components/shared/forms/EditContainer.vue'

import TextInput from '@/components/shared/forms/TextInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    TextInput,
    EditContainer,
    FormRow,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    tagGroup: {
      // If null, a new tag group is created
      type: Object,
    },
  },

  computed: {
    invalidValues() {
      if (this.tagGroup) {
        return this.portfolio.tag_groups.filter((t) => t.name !== this.tagGroup.name).map((t) => t.name)
      } else {
        return this.portfolio.tag_groups.map((t) => t.name)
      }
    },
  },

  watch: {
    portfolio() {
      this.resetModel()
    },

    tagGroup() {
      this.resetModel()
    },
  },

  mounted() {
    if (!this.tagGroup) {
      // Start editing immediately when adding a new group
      this.onFormEvent('edit')
    }
    this.resetModel()
  },

  methods: {
    ...mapActions({
      addTagGroup: 'portfolio/addTagGroup',
      updateTagGroup: 'portfolio/updateTagGroup',
    }),

    resetModel() {
      if (this.tagGroup) {
        this.model = {
          name: this.tagGroup.name,
        }
      } else {
        this.model = {
          name: '',
        }
      }
    },

    async saveHandler() {
      if (this.tagGroup) {
        // Edit
        this.updateTagGroup({
          id: this.tagGroup.id,
          tagGroup: {
            name: this.model.name,
          },
        })
      } else {
        // Create new
        this.addTagGroup({
          portfolio_id: this.portfolio.id,
          name: this.model.name,
        })
      }
    },
  },
}
</script>
