<i18n>
{
  "de": {
    "pageTitle": "Kategorisierungen",
    "settings": "Einstellungen",
    "addButton": "Kategorisierung hinzufügen",
    "nameTitle": "Name",
    "editButton": "Bearbeiten",
    "deleteConfirmation": "Wollen Sie diese Kategorisierung wirklich löschen? Dabei gehen alle Zuordnungen zu Gebäuden verloren.",
    "typeTitle": "Typ",
    "typeAuto": "Automatisch",
    "typeManual": "Manuell",
    "nTags": "Kategorien",
    "noGroups": "In diesem Portfolio sind noch keine Kategorisierungen vorhanden."
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady" class="tag-groups-page">
    <template #default>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio">
        <template #default>
          <div>/</div>
          <router-link :to="`/portfolios/${portfolio.id}/settings`">{{ $t('settings') }}</router-link>
        </template>
      </PageTitle>

      <DetailList v-if="portfolio.tag_groups.length" has-header class="tag-groups-table">
        <template #header>
          <span>{{ $t('nameTitle') }}</span>
          <span>{{ $t('typeTitle') }}</span>
          <span class="align-right">{{ $t('nTags') }}</span>
          <span></span>
        </template>
        <li v-for="(tag_group, index) in portfolio.tag_groups" :key="index" class="tag-group-row">
          <span v-if="tag_group.auto">{{ $t(`_tagGroupNames.${tag_group.name}`) }}</span>
          <span v-else>{{ tag_group.name }}</span>
          <span v-if="tag_group.auto">{{ $t('typeAuto') }}</span>
          <span v-else>{{ $t('typeManual') }}</span>
          <span class="align-right">{{ tag_group.tags.length }}</span>
          <ListButtonWrapper v-if="!tag_group.auto">
            <ListEditButton
              v-if="getPortfolioPermission('EDIT_TAGS')"
              @click="
                editTagGroupId = tag_group.id
                editModalOpen = true
              "
            />
            <ListDeleteButton v-if="getPortfolioPermission('EDIT_TAGS')" @click="onDelete(tag_group)" />
          </ListButtonWrapper>
          <span v-else></span>
        </li>
      </DetailList>
      <div v-else>
        {{ $t('noGroups') }}
      </div>
      <ButtonWrapper>
        <a
          v-if="getPortfolioPermission('EDIT_TAGS')"
          class="button"
          href="#"
          @click.prevent="
            editTagGroupId = null
            editModalOpen = true
          "
          >{{ $t('addButton') }}</a
        >
      </ButtonWrapper>
      <EditTagGroupModal
        v-if="editModalOpen"
        :portfolio="portfolio"
        :tag-group-id="editTagGroupId"
        @close="editModalOpen = false"
      />
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import EditTagGroupModal from '@/components/settings/tag_groups/EditTagGroupModal.vue'
import PageTitle from '@/components/shared/PageTitle.vue'

export default {
  name: 'settingsTagGroups',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
  ],

  components: {
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton,
    ButtonWrapper,
    MainLayout,
    DetailList,
    EditTagGroupModal,
    PageTitle,
  },

  data() {
    return {
      editTagGroupId: null,
      editModalOpen: false,
    }
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),
  },

  methods: {
    ...mapActions({
      deleteTagGroup: 'portfolio/deleteTagGroup',
    }),

    onDelete(tagGroup) {
      const confirmationText = this.$t('deleteConfirmation')
      if (confirm(confirmationText)) {
        this.editing = false
        this.deleteTagGroup(tagGroup)
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style>
.tag-groups-page .tag-groups-table.detail-list > ul > li {
  grid-template-columns: 1fr 100px 120px 120px;
}
.tag-groups-page .align-right {
  text-align: right;
  justify-content: flex-end;
}
</style>
