<i18n>
{
  "de": {
    "loadingLabel": "Kategorien werden geladen",
    "addButton": "Kategorie hinzufügen",
    "nameTitle": "Name",
    "editButton": "Bearbeiten",
    "cancelButton": "Abbrechen",
    "saveButton": "Speichern",
    "alreadyExistsError": "Name bereits vorhanden",
    "pendingLabel": "Wird gespeichert...",
    "deleteConfirmation": "Wollen Sie diese Kategorie wirklich löschen? Dabei gehen alle Zuordnungen zu Gebäuden verloren.",
    "nBuildingsTitle": "Anzahl Gebäude",
    "noTags": "Es sind noch keine Kategorien vorhanden"
  }
}
</i18n>

<template>
  <div class="edit-tag-group-tags">
    <DetailList v-if="(tagGroup && tagGroup.tags.length) || addingTag" has-header class="tags-table">
      <template #header>
        <span>{{ $t('nameTitle') }}</span>
        <span></span>
      </template>

      <li v-for="(tag, index) in sortedTags" :key="index" class="tag-row">
        <span v-if="editTag && editTag.id === tag.id">
          <TextInput
            v-model="tagNameModel"
            :min-length="1"
            :invalid-values="invalidValues"
            :invalid-values-error-message="$t('alreadyExistsError')"
            :edit="true"
          />
        </span>
        <span v-else>{{ tag.name }}</span>
        <ListButtonWrapper v-if="editTag && editTag.id === tag.id">
          <ListSaveButton :disabled="!isValid" @click="onEditSave" />
          <ListButton :img-src="'/icons/x-circle.svg'" :label="$t('cancelButton')" @click="onEditCancel" />
        </ListButtonWrapper>
        <ListButtonWrapper v-else>
          <ListEditButton
            v-if="getPortfolioPermission('EDIT_TAGS') && !editTag && !addingTag"
            @click="onEditTag(tag)"
          />
          <ListDeleteButton
            v-if="getPortfolioPermission('EDIT_TAGS') && !editTag && !addingTag"
            @click="onDelete(tag)"
          />
        </ListButtonWrapper>
      </li>

      <li v-if="addingTag" class="tag-row">
        <TextInput
          v-model="tagNameModel"
          :min-length="1"
          :invalid-values="invalidValues"
          :invalid-values-error-message="$t('alreadyExistsError')"
          :edit="!pending"
        />
        <span v-if="pending">
          {{ $t('pendingLabel') }}
        </span>
        <ListButtonWrapper v-else>
          <ListSaveButton :disabled="!isValid" @click="onAddSave" />
          <ListButton :img-src="'/icons/x-circle.svg'" :label="$t('cancelButton')" @click="onAddCancel" />
        </ListButtonWrapper>
      </li>
    </DetailList>

    <div v-else>
      {{ $t('noTags') }}
    </div>

    <ButtonWrapper class="add-tag-button">
      <a
        v-if="getPortfolioPermission('EDIT_TAGS') && !editTag && !addingTag"
        class="button"
        href="#"
        @click.prevent="onAddTag"
        >{{ $t('addButton') }}</a
      >
    </ButtonWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListButton from '@/components/shared/lists/ListButton.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ListSaveButton from '@/components/shared/lists/ListSaveButton.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'

export default {
  components: {
    ListButton,
    ListButtonWrapper,
    ListEditButton,
    ListSaveButton,
    ListDeleteButton,
    ButtonWrapper,
    DetailList,
    TextInput,
  },

  props: {
    portfolio: {
      type: Object,
    },
    tagGroup: {
      type: Object,
    },
  },

  data() {
    return {
      editTag: null,
      tagNameModel: null,
      addingTag: false,
      pending: false,
    }
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    invalidValues() {
      return this.tagGroup.tags.filter((t) => !this.editTag || t.id !== this.editTag.id).map((t) => t.name)
    },

    isValid() {
      return Boolean(this.tagNameModel && this.invalidValues.includes(this.tagNameModel) === false)
    },

    sortedTags() {
      // Sort tag names by numerical value first (if possible), then as strings
      const numberTags = this.tagGroup.tags.filter((t) => Number(t.name))
      const stringTags = this.tagGroup.tags.filter((t) => !Number(t.name))
      return [
        ...numberTags.sort((a, b) => Number(a.name) - Number(b.name)),
        ...stringTags.sort((a, b) => a.name.localeCompare(b.name)),
      ]
    },
  },

  methods: {
    ...mapActions({
      deleteTag: 'portfolio/deleteTag',
      addTag: 'portfolio/addTag',
      updateTag: 'portfolio/updateTag',
      refreshDetailsById: 'portfolio/refreshDetailsById',
    }),

    onAddTag() {
      this.editTag = null
      this.tagNameModel = ''
      this.addingTag = true
    },

    onAddCancel() {
      this.addingTag = false
    },

    async onAddSave() {
      this.pending = true
      await this.addTag({
        group_id: this.tagGroup.id,
        name: this.tagNameModel,
      })
      await this.refreshDetailsById(this.portfolio.id)
      this.pending = false
      this.addingTag = false
    },

    onEditTag(tag) {
      this.tagNameModel = tag.name
      this.editTag = tag
      this.addingTag = false
    },

    onEditCancel() {
      this.editTag = null
    },

    async onEditSave() {
      await this.updateTag({
        id: this.editTag.id,
        tag: {
          name: this.tagNameModel,
        },
      })
      await this.refreshDetailsById(this.portfolio.id)
      this.editTag = null
    },

    async onDelete(tag) {
      const confirmationText = this.$t('deleteConfirmation')
      if (confirm(confirmationText)) {
        await this.deleteTag(tag)
        await this.refreshDetailsById(this.portfolio.id)
      }
    },
  },
}
</script>

<style lang="scss">
.edit-tag-group-tags {
  & .tags-table.detail-list > ul > li {
    grid-template-columns: 1fr 120px;
  }

  & .tag-row {
    min-height: 60px;
  }

  & .add-tag-button {
    min-height: 36px;
  }
}
</style>
